import React from "react";

export default function BecomeDriver() {
  return (
    <>
      <div
        className="site-breadcrumb"
        style={{ backgroundImage: "assets/img/breadcrumb/01.jpg" }}
      // style="background: url(assets/img/breadcrumb/01.jpg)"
      >
        <div className="container">
          <h2 className="breadcrumb-title">Become a driver</h2>
          <ul className="breadcrumb-menu">
            <li>
              <a href="/">Accueil</a>
            </li>
            <li className="active">Become driver</li>
          </ul>
        </div>
      </div>

      <div className="faq-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="faq-right">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline justify-content-start">
                    Become an Luggin Drivers
                  </span>
                  <h2 className="site-title my-3">
                    General <span>frequently</span> asked questions
                  </h2>
                </div>
                <p className="about-text">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even.
                </p>
                {/*    <div className="faq-img mt-3">
                  <img
                    src="https://live.themewild.com/taxica/assets/img/faq/01.jpg"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>
                        <i className="far fa-question"></i>
                      </span>{" "}
                      Download the app from playStore or AppStore
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="service-single-area py-120">
                        <div className="container">
                          <div className="service-single-wrapper">
                            <div className="row">
                              <div className="col-xl-12 col-lg-12">
                                <div className="service-details">
                                  <div className="service-details">
                                    <div className="row">
                                      <div className="col-md-6 mb-20">
                                        <h3 className="mb-3">App Store</h3>
                                        <img
                                          src="./assets/img/drivers/app_store.jpeg"
                                          alt=""
                                        />
                                      </div>

                                    </div>
                                    <div className="my-4">
                                      <p>
                                        Voici les instructions pour télécharger
                                        l'application Luggin sur les stores :{" "}
                                        <br /> <br />
                                        <h3 className="mb-3">
                                          Sur l'App Store
                                        </h3>
                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Ouvrez l'App Store sur votre iPhone,
                                            iPad ou iPod touch.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Dans
                                            la barre de recherche, saisissez
                                            "Luggin transport".
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            L'application Luggin apparaîtra
                                            dans les résultats de recherche.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>{" "}
                                            Appuyez sur le bouton "Obtenir" pour
                                            télécharger l'application.
                                          </li>
                                          <br />
                                        </ul>
                                        <h3 className="mb-3">
                                          Sur le Play Store
                                        </h3>
                                        <div className="col-md-6 mb-20">
                                          <img
                                            src="./assets/img/drivers/app_store.jpeg"
                                            alt=""
                                          />
                                        </div>
                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Ouvrez le Play Store sur votre
                                            appareil Android.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Dans
                                            la barre de recherche, saisissez
                                            "Luggin transport".
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            L'application Luggin apparaîtra
                                            dans les résultats de recherche.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>{" "}
                                            Appuyez sur le bouton "Installer"
                                            pour télécharger l'application.
                                          </li>
                                          <br />
                                        </ul>
                                        Une fois l'application téléchargée, vous
                                        pouvez l'ouvrir et commencer à
                                        l'utiliser.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span>
                        <i className="far fa-question"></i>
                      </span>
                      Create and account or connect to your account
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="service-single-area py-120">
                        <div className="container">
                          <div className="service-single-wrapper">
                            <div className="row">
                              <div className="col-xl-12 col-lg-12">
                                <div className="service-details">
                                  <div className="service-details">
                                    <div className="row">
                                      <div className="col-md-6 mb-20">
                                        <h3 className="mb-3">
                                          Creation de compte
                                        </h3>
                                        <img
                                          src="./assets/img/drivers/registration.jpg"
                                          alt=""
                                        />
                                      </div>

                                    </div>
                                    <div className="my-4">
                                      <p>
                                        <h3 className="mb-3">
                                          Creation de compte
                                        </h3>
                                        Pour créer un compte Luggin, vous aurez
                                        besoin de fournir les informations
                                        suivantes : <br /> <br />
                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Nom
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Prénom
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Nuéro
                                            de téléphone
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i> Mot
                                            de passe
                                          </li>
                                          <br />
                                        </ul>
                                        L'adresse e-mail est facultative, mais
                                        elle peut être utile pour récupérer
                                        votre mot de passe si vous l'oubliez
                                        <br />
                                        Voici les instructions pour créer un
                                        compte Luggin : <br /> <br />
                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Ouvrez l'application Luggin.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Appuyez sur le bouton "S'inscrire".
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Saisissez votre nom, votre prénom,
                                            votre numéro de téléphone et votre
                                            mot de passe.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>{" "}
                                            Appuyez sur le bouton "Suivant".
                                          </li>
                                          <br />
                                        </ul>
                                        <br /> <br /> Vous recevrez un message
                                        texte avec un code de confirmation.
                                        Saisissez le code de confirmation pour
                                        valider votre inscription. <br />
                                        Une fois votre compte créé, vous pourrez
                                        commencer à utiliser l'application
                                        Luggin pour réserver des taxis.
                                        <br /> Voici quelques conseils pour
                                        créer un mot de passe fort : <br />{" "}
                                        <br />
                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Utilisez au moins 8 caractères, dont
                                            des lettres majuscules, des lettres
                                            minuscules, des chiffres et des
                                            caractères spéciaux.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Ne choisissez pas un mot de passe
                                            facile à deviner, comme votre nom,
                                            votre date de naissance ou votre
                                            adresse.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Ne partagez pas votre mot de passe
                                            avec qui que ce soit.
                                          </li>
                                          <br />
                                          En suivant ces conseils, vous pourrez
                                          protéger votre compte Luggin contre
                                          les pirates.
                                          <br />
                                        </ul>
                                        <br />
                                        <br />

                                        <div className="col-md-6 mb-20">
                                          <h3 className="mb-3">
                                            Se connecter a votre compte
                                          </h3>
                                          <img
                                            src="./assets/img/drivers/connexion.jpg"
                                            alt=""
                                          />
                                        </div>
                                        Voici les instructions pour vous
                                        connecter à votre compte Luggin : <br /><br />
                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Ouvrez l'application Luggin.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Appuyez sur le bouton "Se
                                            connecter".
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Saisissez votre numéro de téléphone
                                            et votre mot de passe.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>{" "}
                                            Appuyez sur le bouton "Se
                                            connecter".
                                          </li>
                                          <br />
                                        </ul>
                                        Si vous avez oublié votre mot de passe,
                                        vous pouvez cliquer sur le lien "Mot de
                                        passe oublié". Vous recevrez un message
                                        texte (sms) avec un code a 6 chiffre pour la  réinitialiser de
                                        votre mot de passe.
                                        <br />
                                        <br />
                                        Une fois connecté, vous pourrez
                                        commencer à réserver des taxis.
                                        <br />
                                        <br />
                                        Voici quelques conseils pour vous
                                        connecter à votre compte Luggin :{" "}
                                        <br />
                                        <br />
                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Assurez-vous que vous avez saisi
                                            votre numéro de téléphone et votre
                                            mot de passe correctement.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Si
                                            vous avez oublié votre mot de passe,
                                            vous pouvez le réinitialiser en
                                            cliquant sur le lien "Mot de passe
                                            oublié".
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Si vous avez toujours des problèmes
                                            pour vous connecter, vous pouvez
                                            contacter le service client
                                            d'Luggin.
                                          </li>
                                          <br />
                                          <br />
                                        </ul>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>
                        <i className="far fa-question"></i>
                      </span>{" "}
                      fill the become driver form in the menu
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="service-single-area py-120">
                        <div className="container">
                          <div className="service-single-wrapper">
                            <div className="row">
                              <div className="col-xl-12 col-lg-12">
                                <div className="service-details">
                                  <div className="service-details">
                                    <div className="row">
                                      <div className="col-md-4 mb-20">
                                        <img
                                          src="./assets/img/drivers/demande.jpeg"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-md-4 mb-20">

                                        <img
                                          src="./assets/img/drivers/menu.jpeg"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-md-4 mb-20">
                                        <img
                                          src="./assets/img/drivers/demande.jpeg"
                                          alt=""
                                        />
                                      </div>

                                    </div>
                                    <div className="my-4">
                                      <p>

                                        Pour envoyer une demande pour Devenir Transpoteur Luggin, vous devez suivre les étapes suivantes :
                                        <br /> <br />

                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Ouvrez l'application Luggin.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Appuyez sur le bouton "Devenir Transpoteur" dans le menu de la page d'accueil.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Remplissez le formulaire de demande de chauffeur.
                                          </li>  <br />
                                          <li>
                                            <i className="fa fa-check"></i>Appuyez sur le bouton "Envoyer".
                                          </li>

                                          <br />
                                        </ul>

                                        Le formulaire de demande de chauffeur vous demandera les informations suivantes :
                                        <br /> <br />

                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Vos informations personnelles
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Vos informations de permis de conduire
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Vos informations de véhicule
                                          </li>

                                          <br />
                                        </ul> Les documents requis pour Devenir Transpoteur Luggin sont les suivants :
                                        <br /> <br />

                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Une copie de votre permis de conduire
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Une copie de votre carte d'identité nationale
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Une copie de la carte grise de votre véhicule
                                          </li> <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Une photo de votre véhicule
                                          </li>

                                          <br />
                                        </ul>
                                        Une fois votre demande envoyée, elle sera examinée par l'équipe de recrutement d'Luggin. Si votre demande est approuvée, vous serez invité à participer à une formation. Après avoir terminé la formation, vous serez un chauffeur Luggin agréé.<br />
                                        <br />Voici quelques conseils pour augmenter vos chances d'être approuvé en tant que chauffeur Luggin : <br /><br />

                                        <ul className="service-single-list">
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Assurez-vous que votre permis de conduire est valide et à jour.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>Assurez-vous que votre véhicule est en bon état et entretenu.
                                          </li>
                                          <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Fournissez des documents clairs et lisibles.
                                          </li> <br />
                                          <li>
                                            <i className="fa fa-check"></i>
                                            Soyez honnête et transparent dans votre demande.
                                          </li>

                                          <br />
                                        </ul>
                                        En suivant ces conseils, vous augmenterez vos chances d'être approuvé en tant que chauffeur Luggin et de commencer à gagner de l'argent en transportant des passagers.

                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
