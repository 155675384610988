export default function AccountDeletion() {

    return (
        <div className="driver-banner">
            <div className="driver-banner bg-primary">
                <div className="container pt-200 pb-100">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h1
                                className="hero-title text-white text-center display-4 text-bold"
                                data-animation="fadeInRight"
                                data-delay=".50s"
                            >
                                Comment supprimer mon compte ?
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-20 mb-50">
                <h1 className="mt-4 mb-4">Suppression de Compte Luggin</h1>
                <p>Vous êtes sur le point de supprimer votre compte Luggin. Veuillez lire attentivement les informations suivantes :</p>

                <h2 className="mt-4 mb-4">Attention :</h2>
                <ul>
                    <li>Cette action est irréversible. Toutes vos données seront supprimées.</li>
                    <li>Vous ne pourrez pas récupérer votre compte après la suppression.</li>
                    <li>Assurez-vous de retirer toutes vos transactions en cours avant de procéder.</li>
                </ul>

                <h2 className="mt-4 mb-4">Étapes pour Supprimer Votre Compte :</h2>
                <ol>
                    <li> 1 - Connectez-vous à votre compte Luggin.</li>
                    <li> 2 - Accédez aux paramètres de votre compte.</li>
                    <li> 3 - Sélectionnez l'option "Supprimer Mon Compte".</li>
                    <li> 4 - Confirmez votre choix en saisissant votre mot de passe.</li>
                    <li> 5 - Cliquez sur "Supprimer le Compte" : Cette opération peut prendre jusqu'à 72 heures pour être effectuée.</li>
                </ol>

                <h2 className="mt-2 mb-2">Contacter le Support :</h2>
                <p>Si vous rencontrez des problèmes ou avez des préoccupations, n'hésitez pas à contacter notre équipe de support à l'adresse support@Luggin.com.</p>

                <p>Nous sommes désolés de vous voir partir. Merci d'avoir utilisé Luggin.</p>
            </div>
        </div>
    )
}