import React from "react";
import { Link } from "react-router-dom";
import BuildBanner from "../../components/banner/banner";

export default function CompanyPage() {
  return (
    <>
      {/* Banner Section */}
      <div className="page-container">
        <BuildBanner
          image=""
          subtitle="Vendez ou achetez des espaces libres dans les bagages lors de vos voyages."
          title="Vendez votre espace bagage, voyagez plus léger avec Luggin"
          className="company-banner banner-gradient"
        />

        {/* Main Section */}
        <div className="bg-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h1 className="mb-20">Libérez de l’espace dans vos bagages et gagnez de l’argent</h1>
                <p className="mb-50">
                  Vous avez de l’espace inutilisé dans vos bagages ? Ou vous avez besoin de transporter des objets supplémentaires pour votre voyage ?
                  Rejoignez notre plateforme et profitez d’une solution simple et sécurisée pour vendre ou acheter des espaces de bagages lors de vos trajets.
                </p>
              </div>
              <div className="col-lg-6 text-center">
                <img className="" src="./assets/img/banner_men.webp" alt="Image de l'entreprise" />
              </div>
            </div>
            <hr className="mt-5" />

            {/* Features Section */}
            <div className="row pt-50 gy-3">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img src="./assets/img/count-1.png" width={100} alt="Vendez de l'espace" />
                <h4 className="mt-4">Vendez de l'espace dans vos bagages</h4>
                <p className="mt-2">
                  Si vous avez de l’espace libre dans vos bagages, vous pouvez le vendre à des voyageurs qui en ont besoin pour transporter des objets.
                </p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img src="./assets/img/count-2.png" width={100} alt="Achetez de l'espace" />
                <h4 className="mt-4">Achetez de l'espace pour vos bagages</h4>
                <p className="mt-2">
                  Si vous avez des objets supplémentaires à transporter, achetez de l’espace de bagages auprès d’autres voyageurs disposant de place.
                </p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img src="./assets/img/count-3.png" width={100} alt="Suivez votre espace" />
                <h4 className="mt-4">Suivi en temps réel de votre espace</h4>
                <p className="mt-2">
                  Suivez vos transactions, gérez vos réservations et consultez les détails des espaces réservés via notre tableau de bord intuitif.
                </p>
              </div>
            </div>
            <hr className="mt-50" />

            {/* Call to Action Section */}
            <div className="container text-center mb-50">
              <img className="rotate" src="./assets/img/wc6.webp" alt="Flotte Luggin" />
              <p className="col-5 mx-auto text-center">
                Rejoignez notre communauté de voyageurs et commencez à acheter ou vendre des espaces de bagages dès aujourd’hui !
              </p>
            </div>

          </div>
        </div>

        {/* How it Works Section */}
        <div className="bg-grey py-100">
          <div className="container">
            <h1 className="mb-20 text-center">Comment ça marche ?</h1>
            <div className="row pt-50 gy-3">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img src="./assets/img/count-1.png" width={100} className="rounded-circle" alt="Créer votre compte" />
                <h4 className="mt-4">Créez votre compte sur Luggin</h4>
                <p className="mt-2">
                  Téléchargez l'application et créez votre compte pour commencer à vendre ou acheter des espaces de bagages lors de vos trajets.
                </p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img src="./assets/img/count-2.png" width={100} className="rounded-circle" alt="Ajoutez vos informations" />
                <h4 className="mt-4">Ajoutez vos informations de voyage</h4>
                <p className="mt-2">
                  Indiquez votre destination, les dates de votre voyage et l’espace disponible dans vos bagages.
                </p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img src="./assets/img/count-3.png" width={100} className="rounded-circle" alt="Réservez ou vendez de l'espace" />
                <h4 className="mt-4">Réservez ou vendez de l’espace</h4>
                <p className="mt-2">
                  Trouvez un espace à acheter ou réservez un espace pour vendre selon votre besoin.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Final Call to Action Section */}
        <div className="mt-100 mb-100 w-100">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-lg-6">
                <h1>Rejoignez notre plateforme et gagnez de l’argent en vendant ou achetant de l’espace dans vos bagages !</h1>
                <p className="mt-4">Inscrivez-vous maintenant et commencez à utiliser Luggin pour vos prochains voyages.</p>

                <div className="nav-right-btn mt-50">
                  <Link to="/driver" className="theme-btn text-white">
                    <span className="fa fa-bag"></span>Commencer à vendre des espaces
                  </Link>
                </div>
              </div>

              <div className="col-lg-6">
                <img src="./assets/img/ab4.webp"  alt="Tableau de bord" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
