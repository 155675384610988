import React from "react";
import { Link } from "react-router-dom";

export default function TransporteurPage() {
    return (
        <>
            <div className="page-container">
                <div className="driver-banner bg-primary">
                    <div className="container pt-200">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1
                                    className="hero-title text-white display-4 text-bold"
                                    data-animation="fadeInRight"
                                    data-delay=".50s"
                                >
                                    Gagnez de l'argent en vendant votre espace libre dans les bagages lors de vos voyages
                                </h1>
                                <p className="mt-4 text-white">Transformez vos voyages en opportunités de revenus en partageant l'espace inutilisé de vos bagages avec des expéditeurs.</p>
                            </div>
                            <div className="col-lg-6">
                                <img src="https://www.seremo.com/assets/images/10.png" alt="Transporteur de colis" />
                            </div>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,256L80,250.7C160,245,320,235,480,245.3C640,256,800,288,960,282.7C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                </div>

                <div className="bg-white">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="mb-20">Pourquoi vendre votre espace libre dans les bagages avec Luggin ?</h1>
                                <p>Que vous soyez en voyage d'affaires ou de loisirs, Luggin vous permet de rentabiliser l'espace inutilisé de vos bagages en le mettant à disposition pour transporter des colis.</p>
                            </div>
                            <div className="col-lg-6">
                                <img className="mt-50" src="./assets/img/space-available.jpg" width={500} alt="Espace libre dans les bagages" />
                            </div>
                        </div>
                        <hr className="mt-5" />

                        <div className="row pt-50 mb-50 gy-3">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/done.png" width={30} alt="" />
                                <h4 className="mt-2">Plus de voyages, plus de gains</h4>
                                <p className="mt-2">Grâce à vos voyages, vous pouvez transporter des colis et gagner de l'argent en plus de vos frais de voyage.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/done.png" width={30} alt="" />
                                <h4 className="mt-2">Voyagez avec un revenu supplémentaire</h4>
                                <p className="mt-2">Vendez l'espace libre dans vos bagages et générez un revenu supplémentaire sans effort.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/done.png" width={30} alt="" />
                                <h4 className="mt-2">Simple, rapide et sécurisé</h4>
                                <p className="mt-2">Inscrivez-vous facilement et commencez à vendre votre espace libre. Nos systèmes garantissent des transactions sécurisées et fiables.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-grey py-100">
                    <div className="container">
                        <h1 className="mb-20 text-center">Devenir un transporteur de colis professionnel</h1>
                        <div className="row pt-50 gy-3">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-1.png" width={100} className="rounded-circle" alt="Créer un compte" />
                                <h4 className="mt-4">Créez votre compte transporteur</h4>
                                <p className="mt-2">Téléchargez l'application, créez un compte, puis commencez à vendre l'espace libre de vos bagages.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-2.png" width={100} className="rounded-circle" alt="Rencontrez le support" />
                                <h4 className="mt-4">Rencontrez notre équipe de support</h4>
                                <p className="mt-2">Notre équipe vous guidera pour vous aider à optimiser votre utilisation de l'espace disponible dans vos bagages.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-3.png" width={100} className="rounded-circle" alt="Activation du compte" />
                                <h4 className="mt-4">Activation et transport des colis</h4>
                                <p className="mt-2">Une fois votre compte validé, vous pouvez commencer à accepter des demandes de transport de colis lors de vos voyages.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-area py-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="mb-20 mt-20">Téléchargez l'application Luggin pour commencer à transporter des colis !</h1>
                                <p className="mb-50">Rejoignez la plateforme de transport de colis dès aujourd'hui. Téléchargez l'application Luggin sur l'Apple Store ou le Play Store.</p>
                                <div className="download-btn">
                                    <a href="https://play.google.com/store/search?q=Luggin&c=apps&gl=FR">
                                        <i className="fab fa-google-play"></i>
                                        <div className="download-btn-content">
                                            <strong>Google Play</strong>
                                        </div>
                                    </a>
                                    <a href="https://apps.apple.com/fr/app/Luggin/id1625930293">
                                        <i className="fab fa-app-store"></i>
                                        <div className="download-btn-content">
                                            <strong>App Store</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                <img className="" src="./assets/img/banner_men_2.webp" alt="Application Luggin" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="app-banner-driver py-120 mt-100 w-100">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-lg-6">
                                <h1>Devenez votre propre patron et transportez des colis avec Luggin !</h1>
                                <p className="mt-4">Il vous suffit de quelques minutes pour commencer à gagner de l'argent en transportant des colis lors de vos voyages.</p>

                                <div className="nav-right-btn mt-50">
                                    <Link to="/becomeCarrier" className="theme-btn">
                                        <span className="fa fa-taxi"></span>Télécharger l'application
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <img className="rounded" src="./assets/img/banner2.jpg" alt="Transporteur de colis" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
