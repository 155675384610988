import BuildBanner from "../components/banner/banner";

export default function Privacy() {

    return (
        <div className="driver-banner">
            <div className="driver-banner bg-primary">
                <div className="container pt-200 pb-100">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h1
                                className="hero-title text-white text-center display-4 text-bold"
                                data-animation="fadeInRight"
                                data-delay=".50s"
                            >
                                Politique de confidentialité
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-20 mb-50">
                <p className="mb-3">Chez ColisPartage, nous accordons une grande importance à la protection de la vie privée de nos utilisateurs. Nous comprenons l'importance de protéger les informations personnelles que vous partagez avec nous lorsque vous utilisez notre application. Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos données personnelles lorsque vous utilisez notre service de transfert de colis entre particuliers.</p>
                <h4 className="mb-3">Collecte d'informations personnelles :</h4>
                <p className="mb-3">Nous collectons des informations personnelles telles que votre nom, votre adresse e-mail, votre numéro de téléphone, vos adresses de ramassage et de livraison, ainsi que des détails concernant le contenu du colis (si nécessaire) et votre mode de paiement lorsque vous utilisez notre application. Nous collectons également des informations concernant votre appareil, votre adresse IP et vos données de navigation afin d'améliorer l'expérience utilisateur.</p>
                <h4 className="mb-3">Utilisation des informations personnelles :</h4>
                <p className="mb-3">Les informations personnelles collectées par ColisPartage sont utilisées pour faciliter le transfert de colis, pour communiquer avec vous concernant vos envois, pour assurer la sécurité des transactions et pour améliorer notre service. Nous les utilisons également pour personnaliser les offres et les promotions, et pour effectuer des analyses statistiques afin de rendre notre application plus performante.</p>
                <h4 className="mb-3">Partage des informations personnelles :</h4>
                <p className="mb-3">Nous partageons certaines de vos informations personnelles avec nos partenaires de service, tels que les transporteurs et les prestataires de paiement. Nous pouvons également partager des informations avec les autorités gouvernementales si nécessaire pour respecter la législation en vigueur.</p>
                <h4 className="mb-3">Protection des informations personnelles :</h4>
                <p className="mb-3">Chez ColisPartage, nous prenons la sécurité des informations personnelles très au sérieux. Nous avons mis en place des mesures de sécurité techniques et administratives pour protéger vos données contre tout accès non autorisé, perte ou utilisation abusive.</p>
            </div>
        </div>
    );
}
