import React from "react";
import { Link } from "react-router-dom";
import "./client.css";
import BuildBanner from "../../components/banner/banner";

export default function ClientPage() {
    return (
        <>
            <div className="page-container">
                <BuildBanner
                    image=""
                    subtitle="Facilitez vos voyages en optimisant l’espace dans vos bagages."
                    title="Vendez et achetez de l’espace de bagage entre particuliers"
                    className="client-banner banner-gradient"
                />
                <div className="pt-20 pb-20">
                    <div className="container">
                        <h1 className="text-center mb-100">Une solution simple pour optimiser vos trajets et vos bagages ! Avec notre application, voyagez léger</h1>
                        <div className="row nopadding">
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-grey">
                                <div className="list-items-img">
                                    <h4 className="mt-2">Assistance Client</h4>
                                    <p className="mt-2">Notre équipe est disponible pour répondre à toutes vos questions. Contactez-nous directement via l'application ou par téléphone pour toute assistance.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-secondary">
                                <div className="list-items-img">
                                    <h4 className="mt-2 text-white">Évaluation des Espaces de Bagages</h4>
                                    <p className="mt-2">Les évaluations sont essentielles pour nous aider à garantir des espaces de bagages de qualité et sécurisés entre les utilisateurs.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-secondary">
                                <div className="list-items-img">
                                    <h4 className="mt-2 text-white">Assistance Urgente</h4>
                                    <p className="mt-2">En cas de problème avec un espace de bagage ou une transaction, utilisez notre fonctionnalité d'assistance d'urgence pour obtenir de l’aide rapidement.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-grey">
                                <div className="list-items-img">
                                    <h4 className="mt-2">Sécurisation des Transactions</h4>
                                    <p className="mt-2">Toutes vos transactions sont sécurisées grâce à notre système de paiement en ligne. Achetez et vendez des espaces de bagages en toute confiance.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-grey">
                                <div className="list-items-img">
                                    <h4 className="mt-2">Options de Paiement</h4>
                                    <p className="mt-2">Nous proposons diverses options de paiement : carte bancaire, Mobile Money, ou transfert bancaire pour plus de flexibilité.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-secondary">
                                <div className="list-items-img">
                                    <h4 className="mt-2 text-white">Partage d’Informations de Voyage</h4>
                                    <p className="mt-2">Partagez les informations de votre voyage, comme le modèle de véhicule et la localisation en temps réel, avec les autres utilisateurs pour plus de sécurité et de transparence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="app-banner-driver py-120 mt-100 w-100">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-lg-6">
                                <h1>Téléchargez l’application maintenant et commencez à vendre ou acheter des espaces de bagages !</h1>
                                <p className="mt-4">L’inscription est rapide et simple, pour des voyages légers et sans tracas.</p>

                                <div className="download-btn mt-20">
                                    <a href="https://play.google.com/store/search?q=Luggin&c=apps&gl=FR">
                                        <i className="fab fa-google-play"></i>
                                        <div className="download-btn-content">
                                            <strong>Google Play</strong>
                                        </div>
                                    </a>
                                    <a href="https://apps.apple.com/fr/app/Luggin/id1625930293">
                                        <i className="fab fa-app-store"></i>
                                        <div className="download-btn-content">
                                            <strong>App Store</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <img className="rounded" src="./assets/img/banner_men_2.webp" alt="App download" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white py-100">
                    <div className="container">
                        <h1 className="mb-30">Questions fréquentes des utilisateurs</h1>
                        <div className="col-lg-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Comment puis-je vendre mon espace de bagages ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Pour vendre votre espace de bagages, créez un profil, ajoutez les détails de votre voyage et l’espace disponible, puis validez votre annonce.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                        >
                                            Comment puis-je acheter un espace de bagages ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Pour acheter un espace de bagages, parcourez les annonces disponibles et contactez le vendeur pour finaliser la transaction.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="true"
                                            aria-controls="collapseThree"
                                        >
                                            Est-ce que les transactions sont sécurisées ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Oui, toutes les transactions sont sécurisées grâce à notre système de paiement intégré. Vos informations sont protégées à chaque étape.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="true"
                                            aria-controls="collapseFour"
                                        >
                                            Puis-je annuler une transaction ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Oui, vous pouvez annuler une transaction avant qu'elle ne soit confirmée par les deux parties. Une fois confirmée, la transaction est définitive.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-10 mb-20">
                    <div className="text-center">
                        <h3 className="text-secondary">Vous avez une question ou une demande ?</h3>
                        <p>Nous sommes là pour vous aider. <Link to="/contactus">Contactez-nous ici</Link>.</p>
                    </div>
                </div>
            </div>
        </>
    );
}
