import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      {" "}
      <footer className="footer-area" >
        <div className="footer-widget">
          <div className="container">
            <div className="row footer-widget-wrapper pt-120 pb-70">
              <div className="col-md-6 col-lg-4">
                <div className="footer-widget-box about-us">
                  <a href="#" className="footer-logo">
                    <img src="./assets/img/logo-trs.png" style={{ width: '90px', }} alt="" />
                  </a>
                  <p className="mb-3">
                    Vous avez une question sur les démarches à suivre ? Nous sommes là pour vous répondre.
                  </p>
                  <ul className="footer-contact">
                    <li>
                      <a href="tel:0033618005942">
                        <i className="fa fa-phone"></i>(+33) 618 005 942
                      </a>
                    </li>
                    <li>
                      <a href="mailto:contact@luggin.com">
                        <i className="fa fa-envelope"></i>
                        <span
                          className="__cf_email__"
                          data-cfemail="a9c0c7cfc6e9ccd1c8c4d9c5cc87cac6c4"
                        >
                          contact@Luggin.com
                        </span>
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-map-marker-alt"></i> Paris, France
                    </li>

                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="footer-widget-box list">
                  <h4 className="footer-widget-title">Liens Rapides.</h4>
                  <ul className="footer-list">
                    <li>
                      <a href="#">
                        <i className="fa fa-caret-right"></i> Accueil
                      </a>
                    </li>
                    <li>
                      <a href="/#/client">
                        <i className="fa fa-caret-right"></i> Trajets
                      </a>
                    </li>
                    <li>
                      <a href="/#/company">
                        <i className="fa fa-caret-right"></i> Demandes
                      </a>
                    </li>
                    <li>
                      <a href="/#/contactus">
                        <i className="fa fa-caret-right"></i> Contact
                      </a>
                    </li>
                    <li>
                      <Link to="/privacy">
                        <i className="fa fa-caret-right"></i> Confidentialité
                      </Link>
                    </li>


                  </ul>
                </div>
              </div>
              {/*   <!--      <div className="col-md-6 col-lg-3">
          <div className="footer-widget-box list">
            <h4 className="footer-widget-title">Support Center</h4>
            <ul className="footer-list">
              <li>
                <a href="#"><i className="fa fa-caret-right"></i> FAQ's</a>
              </li>
              <li>
                <a href="#"
                  ><i className="fa fa-caret-right"></i> Affiliates</a
                >
              </li>
              <li>
                <a href="#"
                  ><i className="fa fa-caret-right"></i> Booking Tips</a
                >
              </li>
              <li>
                <a href="#"
                  ><i className="fa fa-caret-right"></i> Book A Ride</a
                >
              </li>
              <li>
                <a href="#"
                  ><i className="fa fa-caret-right"></i> Contact Us</a
                >
              </li>
              <li>
                <a href="#"><i className="fa fa-caret-right"></i> Sitemap</a>
              </li>
            </ul>
          </div>
        </div> --> */}
              <div className="col-md-6 col-lg-4">
                <div className="footer-widget-box list">
                  <h4 className="footer-widget-title">Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Abonnez-vous à notre newsletter pour recevoir les
                      dernières mises à jour et actualités.
                    </p>
                    <div className="subscribe-form">
                      <form action="#">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Votre Email"
                        />
                        <button className="theme-btn text-white" type="submit">
                          Abonnez-vous <i className="fa fa-paper-plane"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright" >
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p className="copyright-text">
                  &copy; Copyright <span id="date"></span>
                  <a href="#"> Luggin </a> All Rights Reserved.
                </p>
              </div>
              <div className="col-md-6 align-self-center">
                <ul className="footer-social">
                  <li>
                    <a href="https://www.facebook.com/luggin">
                      <i className="fab fa-facebook-f bg-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/luggin">
                      <i className="fab fa-instagram bg-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#"><i className="fab fa-linkedin-in bg-white"></i></a>
                  </li>
                  <li>
                    <a href="#"><i className="fab fa-youtube bg-white"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
