import React, { useEffect, useState } from "react";
import SlideShow from "../../components/slide-show";
import ServiceWidget from "../../components/services/service_widget";
import { Link } from "react-router-dom";

export default function Home() {


  const serviceData = [
    {
      libelle: 'Proposer un espace',
      description: 'Publiez votre espace de bagage disponible et aidez d’autres à transporter leurs objets en toute confiance.',
      image: './assets/img/banner_img_1.webp  ',
      className: "bg-white"
    },
    {
      libelle: 'Réserver un espace',
      description: 'Trouvez et réservez facilement un espace de bagage sur le trajet de votre choix pour expédier vos biens.',
      image: './assets/img/banner_men_2.webp',
      className: "bg-primary"
    },
    {
      libelle: 'Partage de trajet',
      description: 'Optimisez vos trajets en partageant l’espace libre de votre bagage avec d’autres voyageurs.',
      image: './assets/img/banner_men.webp',
      className: "bg-primary"
    },
    {
      libelle: 'Sécurité et suivi',
      description: 'Profitez d’un suivi en temps réel et de transactions sécurisées pour un transport sans souci.',
      image: './assets/img/ab4.webp',
      className: "bg-white"
    },
    // Ajoutez d'autres services selon vos besoins
  ];


  return (
    <>
      <main className="main">
        <SlideShow />
        <div className="about-area pt-60 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="site-heading text-center">
                  <h2 className="site-title">
                    Monétisez vos kilos inutilisés
                  </h2>
                  {/* <div className="heading-divider"></div> */}
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="about-left wow fadeInLeft"
                  data-wow-delay=".25s"
                >
                  <div className="about-img">
                    <img
                      src="./assets/img/luggin-frame.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="about-right wow fadeInRight"
                  data-wow-delay=".25s"
                >
                  <div className="about-list-wrapper">
                    <ul className="about-list list-unstyled">
                      <li>
                        <div className="div-left mt-20 mr-2">
                          <img src="./assets/img/count-1.png" alt="" className="w-30" />
                        </div>
                        <div className="div-right">
                          <h4 className="service-title">
                            Profitez d'un espace de bagage fiable
                          </h4>
                          <p className="service-text">
                            Découvrez comment Luggin facilite la connexion entre voyageurs et particuliers souhaitant expédier des biens. Vendez ou louez l'espace inutilisé de votre bagage pour rentabiliser vos déplacements.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="div-left mt-20 mr-2">
                          <img src="./assets/img/count-2.png" alt="" className="w-30" />
                        </div>
                        <div className="div-right">
                          <h4 className="service-title">
                            Une variété d'options pour répondre à vos besoins
                          </h4>
                          <p className="service-text">
                            Que vous transportiez des objets légers ou volumineux, trouvez l'espace de bagage parfait parmi notre sélection. Nos utilisateurs peuvent choisir la solution la mieux adaptée à leurs besoins spécifiques.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="div-left mt-20 mr-2">
                          <img src="./assets/img/count-3.png" alt="" className="w-20" />
                        </div>
                        <div className="div-right">
                          <h4 className="service-title">
                            Voyagez et gagnez en toute confiance
                          </h4>
                          <p className="service-text">
                            Grâce à notre système de sécurité et de transparence, gérez vos transactions en toute confiance. Nos tarifs clairs et notre suivi garantissent une expérience sans souci pour les expéditeurs et les transporteurs.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="service-area bg-grey py-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <h1>Nos solutions de transport de bagages</h1>
                <div className="divider"></div>
                <p className="mt-10">
                  Découvrez nos options ! Notre plateforme est conçue pour connecter les voyageurs et ceux ayant besoin d'expédier leurs biens. Que vous souhaitiez proposer un espace de bagage, réserver un espace disponible ou partager un trajet, Luggin vous offre des solutions flexibles et sécurisées pour répondre à vos besoins de transport.
                </p>
              </div>

              <div className="col-lg-8">
                <div className="row">
                  {serviceData.map((data, index) => (
                    <div className="col-6  nopadding">
                      <ServiceWidget key={index} libelle={data.libelle} description={data.description} image={data.image} className={data.className} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cars-banner">
          <div className="container align-items-center py-120 wow fadeInUp">
            <h1 className="text-white text-center site-title">Une Application, des Solutions de Transport de Bagages Innovantes</h1>
            <h4 className="text-center text-white mt-4">
              Luggin réunit différents services de transport de bagages, facilitant la connexion entre voyageurs et expéditeurs. Simplifiez vos envois et rentabilisez vos trajets grâce à notre plateforme sécurisée et pratique.
            </h4>
            <h1 className="text-white text-center mt-4 Luggin-large wow fadeInUp" data-wow-delay=".25s">Luggin</h1>

          </div>
        </div>



        {/* <div className="counter-area pb-90">
          <div className="container">
            <div className="counter-wrapper">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/taxi-1.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to="500"
                        data-speed="3000"
                      >
                        500
                      </span>
                      <h6 className="title">+ Véhicules disponibles</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/happy.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to="900"
                        data-speed="3000"
                      >
                        900
                      </span>
                      <h6 className="title">+ Clients satisfaits.</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/driver.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to="700"
                        data-speed="3000"
                      >
                        700
                      </span>
                      <h6 className="title">+ Nos chauffeurs.</h6>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/trip.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to="1800"
                        data-speed="3000"
                      >
                        1800
                      </span>
                      <h6 className="title">+ Courses terminées</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="feature-area bg py-120">
          <div className="container mt-150">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="site-heading text-center">
                  <span className="site-title-tagline">Luggin Sûrement !</span>
                  <h2 className="site-title">Pourquoi choisir Luggin ?</h2>
                  <div className="heading-divider"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div
                  className="feature-item wow fadeInUp"
                  data-wow-delay=".25s"
                >
                  <div className="feature-icon">
                    <img
                      src="https://live.themewild.com/taxio/assets/img/icon/taxi-safety.svg"
                      alt=""
                    />
                  </div>
                  <div className="feature-content">
                    <h4>Sécurité Guarantie</h4>
                    <p>Définissez vos propres horaires.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="feature-item mt-lg-5 wow fadeInDown"
                  data-wow-delay=".25s"
                >
                  <div className="feature-icon">
                    <img
                      src="https://live.themewild.com/taxio/assets/img/icon/pickup.svg"
                      alt=""
                    />
                  </div>
                  <div className="feature-content">
                    <h4>fat Pickup</h4>
                    <p>Générez plus de revenus avec Luggin.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="feature-item wow fadeInUp"
                  data-wow-delay=".25s"
                >
                  <div className="feature-icon">
                    <img
                      src="https://live.themewild.com/taxio/assets/img/icon/money.svg"
                      alt=""
                    />
                  </div>
                  <div className="feature-content">
                    <h4>Affordable Rate</h4>
                    <p>Soyez votre propre patron.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="feature-item mt-lg-5 wow fadeInDown"
                  data-wow-delay=".25s"
                >
                  <div className="feature-icon">
                    <img
                      src="https://live.themewild.com/taxio/assets/img/icon/support.svg"
                      alt=""
                    />
                  </div>
                  <div className="feature-content">
                    <h4>24/7 Support</h4>
                    <p>Un service 100% sécurisé.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/*   <!--     <div className="taxi-rate py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Taxi Rate</span>
                <h2 className="site-title">Our Taxi Rate For You</h2>
                <div className="heading-divider"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="rate-item wow fadeInUp" data-wow-delay=".25s">
                <div className="rate-header">
                  <div className="rate-img">
                    <img src="https://live.themewild.com/taxio/assets/img/rate/01.png" alt />
                  </div>
                </div>
                <div className="rate-header-content">
                  <h4>Basic Pakage</h4>
                  <p className="rate-duration">One Time Payment</p>
                </div>
                <div className="rate-content">
                  <div className="rate-icon">
                    <img src="https://live.themewild.com/taxio/assets/img/icon/taxi-1.svg" alt />
                  </div>
                  <div className="rate-feature">
                    <ul>
                      <li>
                        <i className="fa fa-check-double"></i> Base Charge:
                        <span>$2.30</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Distance Allowance:
                        <span>5000m</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Up To 50 kms:
                        <span>$1.38/km</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Booking Fee:
                        <span>$0.99</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Extra Passangers:
                        <span>$0.45</span>
                      </li>
                    </ul>
                    <a href="#" className="theme-btn"
                      >Choose Plan<i className="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="rate-item wow fadeInDown" data-wow-delay=".25s">
                <div className="rate-header">
                  <div className="rate-img">
                    <img src="https://live.themewild.com/taxio/assets/img/rate/01.png" alt />
                  </div>
                </div>
                <div className="rate-header-content">
                  <h4>Standard Pakage</h4>
                  <p className="rate-duration">One Time Payment</p>
                </div>
                <div className="rate-content">
                  <div className="rate-icon">
                    <img src="https://live.themewild.com/taxio/assets/img/icon/taxi-1.svg" alt />
                  </div>
                  <div className="rate-feature">
                    <ul>
                      <li>
                        <i className="fa fa-check-double"></i> Base Charge:
                        <span>$2.30</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Distance Allowance:
                        <span>5000m</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Up To 50 kms:
                        <span>$1.38/km</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Booking Fee:
                        <span>$0.99</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Extra Passangers:
                        <span>$0.45</span>
                      </li>
                    </ul>
                    <a href="#" className="theme-btn"
                      >Choose Plan<i className="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="rate-item wow fadeInUp" data-wow-delay=".25s">
                <div className="rate-header">
                  <div className="rate-img">
                    <img src="https://live.themewild.com/taxio/assets/img/rate/01.png" alt />
                  </div>
                </div>
                <div className="rate-header-content">
                  <h4>Premium Pakage</h4>
                  <p className="rate-duration">One Time Payment</p>
                </div>
                <div className="rate-content">
                  <div className="rate-icon">
                    <img src="https://live.themewild.com/taxio/assets/img/icon/taxi-1.svg" alt />
                  </div>
                  <div className="rate-feature">
                    <ul>
                      <li>
                        <i className="fa fa-check-double"></i> Base Charge:
                        <span>$2.30</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Distance Allowance:
                        <span>5000m</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Up To 50 kms:
                        <span>$1.38/km</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Booking Fee:
                        <span>$0.99</span>
                      </li>
                      <li>
                        <i className="fa fa-check-double"></i> Extra Passangers:
                        <span>$0.45</span>
                      </li>
                    </ul>
                    <a href="#" className="theme-btn"
                      >Choose Plan<i className="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> --> */}

        {/*    <!--  <div className="team-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Drivers</span>
                <h2 className="site-title">Our Expert Drivers</h2>
                <div className="heading-divider"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="team-item wow fadeInUp" data-wow-delay=".25s">
                <div className="team-img">
                  <img src="https://live.themewild.com/taxio/assets/img/team/01.jpg" alt="thumb" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h5><a href="#">Alma Mcelroy</a></h5>
                    <span>Expert Driver</span>
                  </div>
                </div>
                <div className="team-social">
                  <a href="#"><i className="fab fa-facebook-f"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-linkedin-in"></i></a>
                  <a href="#"><i className="fab fa-youtube"></i></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="team-item wow fadeInDown" data-wow-delay=".25s">
                <div className="team-img">
                  <img src="https://live.themewild.com/taxio/assets/img/team/02.jpg" alt="thumb" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h5><a href="#">Marie Hooks</a></h5>
                    <span>Expert Driver</span>
                  </div>
                </div>
                <div className="team-social">
                  <a href="#"><i className="fab fa-facebook-f"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-linkedin-in"></i></a>
                  <a href="#"><i className="fab fa-youtube"></i></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="team-item wow fadeInUp" data-wow-delay=".25s">
                <div className="team-img">
                  <img src="https://live.themewild.com/taxio/assets/img/team/03.jpg" alt="thumb" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h5><a href="#">Daniel Nesmith</a></h5>
                    <span>Expert Driver</span>
                  </div>
                </div>
                <div className="team-social">
                  <a href="#"><i className="fab fa-facebook-f"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-linkedin-in"></i></a>
                  <a href="#"><i className="fab fa-youtube"></i></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="team-item wow fadeInDown" data-wow-delay=".25s">
                <div className="team-img">
                  <img src="https://live.themewild.com/taxio/assets/img/team/04.jpg" alt="thumb" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h5><a href="#">Gertrude Barrow</a></h5>
                    <span>Expert Driver</span>
                  </div>
                </div>
                <div className="team-social">
                  <a href="#"><i className="fab fa-facebook-f"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-linkedin-in"></i></a>
                  <a href="#"><i className="fab fa-youtube"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> --> */}

        {/* <div className="choose-area  py-120">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="choose-content">
                  <div
                    className="site-heading wow fadeInDown mb-4"
                    data-wow-delay=".25s"
                  >
                    <span className="site-title-tagline text-white justify-content-start">
                      <i className="flaticon-drive"></i> POURQUOI NOUS CHOISIR
                    </span>
                    <h2 className="site-title text-white mb-10">
                      Notre engagement est de vous offrir un <span>service de qualité</span> exceptionnelle.
                    </h2>
                    <p className="text-white">
                      Luggin est l'appli de mobilité tout-en-un. Soyez pris en charge par un chauffeur avec un service première classe en quelques minutes et profitez d'un trajet confortable où que vous alliez ; ou évitez les embouteillages avec l'une de nos trottinettes leader du marché.
                    </p>
                  </div>
                  <div
                    className="choose-img wow fadeInUp"
                    data-wow-delay=".25s"
                  >
                    <img className="rounded" src="./assets/img/3.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="choose-content-wrapper wow fadeInRight"
                  data-wow-delay=".25s"
                >
                  <div className="choose-item">
                    <span className="choose-count">01</span>
                    <div className="choose-item-icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/taxi-1.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-item-info">
                      <h3>Large gamme de véhicules</h3>
                      <p>
                        Notre gamme diversifiée de véhicules est prête à satisfaire toutes vos exigences particulières, que vous ayez besoin d'un transport individuel, en groupe, ou pour des besoins spécifiques.
                      </p>
                    </div>
                  </div>
                  <div className="choose-item ms-lg-5">
                    <span className="choose-count">02</span>
                    <div className="choose-item-icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/driver.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-item-info">
                      <h3>Chauffeurs professionnels </h3>
                      <p>
                        Nos chauffeurs sont des experts qualifiés et chevronnés, garantissant ainsi votre sécurité et votre bien-être tout au long de votre déplacement.
                      </p>
                    </div>
                  </div>
                  <div className="choose-item ms-lg-5">
                    <span className="choose-count">03</span>
                    <div className="choose-item-icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/driver.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-item-info">
                      <h3>Un niveau de service client exceptionnel. </h3>
                      <p>
                        La satisfaction de nos clients est une priorité absolue pour nous. Notre équipe de service client est à votre disposition pour répondre à vos questions et vous apporter une assistance rapide en cas de besoin.
                      </p>
                    </div>
                  </div>
                  <div className="choose-item ms-lg-5">
                    <span className="choose-count">03</span>
                    <div className="choose-item-icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/taxi-location.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-item-info">
                      <h3>Tarifs compétitifs </h3>
                      <p>
                        Nous proposons des tarifs concurrentiels et clairs, ce qui signifie que vous pouvez bénéficier d'un service de qualité exceptionnelle à un coût abordable.
                      </p>
                    </div>
                  </div>
                  <div className="choose-item mb-lg-0">
                    <span className="choose-count">03</span>
                    <div className="choose-item-icon">
                      <img
                        src="https://live.themewild.com/taxio/assets/img/icon/taxi-location.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-item-info">
                      <h3>Fiabilité</h3>
                      <p>
                        Nous sommes une source de fiabilité et de ponctualité. Vous pouvez avoir toute confiance en notre capacité à respecter les horaires et à répondre à vos besoins.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="contact-area py-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h1 className="mb-20 mt-20">Téléchargez gratuitement la première application de partage d'espace de bagage</h1>
                <p className="mb-50">Transformez vos trajets en opportunités avec Luggin : réservez ou proposez un espace de bagage en quelques clics. Téléchargez l'application dès maintenant sur l'Apple Store et le Play Store pour simplifier vos envois et rentabiliser vos déplacements.</p>
                <div className="download-btn">
                  <a href="https://play.google.com/store/search?q=Luggin&c=apps&gl=FR">
                    <i className="fab fa-google-play"></i>
                    <div className="download-btn-content">
                      <strong>Google Play</strong>
                    </div>
                  </a>
                  <a href="https://apps.apple.com/fr/app/Luggin/id1625930293">
                    <i className="fab fa-app-store"></i>
                    <div className="download-btn-content">
                      <strong>App Store</strong>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <img className="" src="./assets/img/banner_img_1.webp" alt="Application Luggin" />
              </div>
            </div>
          </div>
        </div>


      </main>
    </>
  );
}
