
import React from 'react';
import './service_css.css';

interface User {
    libelle: string;
    description: string;
    image: string;
    className?: string;
}

function ServiceWidget(params: User) {

    const cardClassName = `service-card wow fadeInUp ${params.className || ''}`;

    return (
        <>
            <div className={cardClassName} data-wow-delay=".25s">
                <img src={params.image} alt="logo-sservice" />
                <div className='service-contain'>
                    <h4 className='mb-2'>{params.libelle}</h4>
                    <p> {params.description}</p>
                </div>
            </div>
        </>
    );
}

export default ServiceWidget;